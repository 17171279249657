import React from "react"
import { FormattedMessage } from "react-intl"
import Link from "../Link"
import { limitText } from "../../utils/text"

import "./style.scss"

export default function TourBoxes({ tours, title, isLectures = false }) {
  return (
    <div className="tour-boxes">
      <h2>{title}</h2>
      <div className="tours-display">
        {tours.map(tour => (
          <div key={tour.title} className="box">
            <img alt="tour" src={tour.imgs[0]?.url} />
            <h3 className="location">
              {!tour.location ? tour.dates[0]?.location : tour.location}
            </h3>
            <h4 className="title">{limitText(tour.title, 25)}</h4>
            <p className="content">{limitText(tour.content, 130)}</p>
            <p className="date">
              {tour.dates.length
                ? new Date(tour.dates[0].time).toLocaleDateString("en-GB")
                : ""}
            </p>
            <Link
              href={!tour.location ? "/lecture" : "/tour"}
              state={{ tour }}
              className="tour-cta"
            >
              <FormattedMessage id="global_extra-details" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
