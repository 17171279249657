import React, { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TourBoxes from "../components/TourBoxes"

import "../styles/lectures.scss"

export default function Lectures({ pageContext }) {
  const lectures = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("lectures")).filter(
            l => l.lng === pageContext.language
          )
        : [],
    []
  )

  return (
    <Layout pageContext={pageContext}>
      <div className="lectures-page">
        <div className="page-banner banner">
          <div className={`page-banner-content ${pageContext.language}`}>
            <h1>
              <FormattedMessage id="global_lectures" />
            </h1>
          </div>
        </div>
        <TourBoxes
          tours={lectures}
          title={<FormattedMessage id="lectures_our-lectures" />}
        />
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
